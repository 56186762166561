const enGb = {
    global: {
        appName: "Digital Sales Assistant",
        articleGroups: "Article groups",
        articleNumber: "Article number",
        fabricComposition: "Fabric composition",
        typeToSearch: "Type to search...",
        currentCustomer: "Current customer",
        deselectCustomer: "Deselect customer",
        collection: "Collection",
        searchTermResults: (searchterm) => `Search results for "${searchterm}"`,
        articlesAmount: (amount) => `${amount} article(s)`,
        showArticlesAmount: (amount) => `View ${amount} article(s)`,
        filters: "Filters",
        categories: "Categories",
        color: "Color",
        colors: "Colors",
        size: "Size",
        sizes: "Sizes",
        brand: "Brand",
        brands: "Brands",
        gender: "Gender",
        search: "Search",
        price: "Price",
        name: "Name",
        name2: "Name 2",
        noFiltersFound: (filterName) => `No ${filterName} found.`,
        moreFilters: (filterName) => `More ${filterName}`,
        lessFilters: (filterName) => `Less ${filterName}`,
        inStock: "In stock",
        selectedFilters: "Selected filter(s)",
        deleteAllFilters: "Delete all",
        sortOptionName: "Name",
        sortOptionPriceAsc: "Price ascending",
        sortOptionPriceDesc: "Price descending",
        amount: "Amount",
        day: "Day",
        time: "Time",
        reason: "Reason",
        noProductFound: "Product not found",
        backToOverview: "Back to collection",
        back: "Back",
        goBack: "go back",
        gallery: "Gallery",
        productInformation: "Product information",
        stockInformation: "Stock information",
        shelfInformation: "Shelf information",
        expectedInventoryInformation: "Expected inventory",
        noStockInfoFound: "No stock information was found.",
        noSizesFound: "No sizes were found.",
        noStoresFound: "No stores were found",
        noPickupPointsFoundWithZipcode: (name) => `No ${name} pickup points were found with the specified zipcode.`,
        noStockFound: "No stock was found.",
        totalStock: "Total stock",
        maxToOrder: "Max. to order",
        moreBranches: "More branches",
        lessBranches: "Less branches",
        addToCart: "Add to cart",
        kioskMode: "Kiosk mode",
        on: "On",
        viewStockInformation: "View stock information",
        viewShelfInformation: "View shelf information",
        none: "None",
        isSellableWithoutStockClarification: "Note: This article has no stock. When ordering, a back order will be created.",
        isSellableWithoutStockWithStockClarification: (amountType, amount) =>
            `Note: For this article ${amountType === "plural" ? "there is" : "there are"} ${amount} in stock. If you order more, a back order will be created.`,
        externalStock: "External stock",
        setHasFollowingProducts: "This set consists of the following products",
        confirm: "Confirm",
        vendorArticleNumber: "Vendor article number",
        requestedDeliveryDate: "Requested delivery date",
        requestedPickupDate: "Requested pickup date",
        pickupDate: "Pickup date",
        deliveryDate: "Delivery date",
        xItemsExpectedOnDate: (amount, date) => (amount === 1 ? `${amount} item on ${date}` : `${amount} items on ${date}`),
        noExpectedInventoryFound: "No expected stock found.",
        information: "Information",
        inventory: "Inventory",
        informationAboutArticle: "Information about article",
        moreInformation: "More information",
        upwardsOf: "From",
        filterProperties: "Filter properties...",
        barcode: "Barcode",
        ean: "EAN",
        selectOne: "Select one...",
        outOfStock: "Out of stock",
        searchInAllCategories: "Search in all categories",
        searchWithoutFilters: "Search without filters"
    },
    checkout: {
        shoppingCart: "shopping cart",
        customerSelection: "Customer selection",
        customerDetails: "Customer data",
        orderOverview: "Order overview",
        billingAddress: "Billing address",
        otherAddress: "Other address",
        deliveryMethod: "Delivery method",
        homeDelivery: "Home delivery",
        storeDelivery: "Pick up in store",
        postNlDelivery: "Pick up at a PostNL point",
        dhlDelivery: "Pick up at a DHL point",
        pickupPointDelivery: "Pick up at a pick up point",
        free: "Free",
        mr: "Mr.",
        mrs: "Mrs.",
        continue: "Continue",
        continueOrdering: "Continue ordering",
        editTotalDiscount: "Change coupon discount",
        applyTotalDiscount: "Apply coupon discount",
        removeTotalDiscount: "Remove coupon discount",
        editDiscount: "Change discount",
        applyDiscount: "Apply discount",
        removeDiscount: "Remove discount",
        subTotal: "Subtotal",
        discount: "Discount",
        chooseDiscountReason: "Choose discount reason",
        shippingCosts: "Shipping costs",
        total: "Total",
        currencyAmount: "Amount",
        percentage: "Percentage",
        delete: "Remove",
        cancel: "Cancel",
        save: "Save",
        previous: "Previous",
        next: "Next",
        toDetails: "To details",
        storeSafetyCode: "Shop security code",
        login: "Log in",
        customer: "Customer",
        customerNumber: "Customer number",
        customerNumberAbbr: "Customernr.",
        firstName: "First name",
        infix: "Infix",
        lastName: "Last name",
        postalCode: "Zip code",
        houseNumber: "House number",
        houseNumberAbbr: "House No.",
        address: "Address",
        emailAddress: "E-mail address",
        emailAddressAbbr: "E-mail",
        action: "Action",
        select: "Select",
        chooseOtherCustomer: "Choose other customer",
        results: "Results",
        searchBy: "Search by",
        postalCodeHouseNumber: "Zip code + House number",
        condition: "Condition",
        beginsWith: "Starts with",
        endsWith: "Ends with",
        equals: "Equal to",
        contains: "Contains",
        searchTerm: "Search term",
        searchCustomer: "Customer search",
        createCustomer: "Create customer",
        continueAsGuest: "Continue without a customer card",
        or: "Or",
        edit: "Edit",
        deliveryAddress: "Delivery address",
        theNetherlands: "The Netherlands",
        belgium: "Belgium",
        germany: "Germany",
        country: "Country",
        houseNumberExtension: "Addition",
        street: "Street",
        city: "City",
        salutation: "Salutation",
        phonenumber: "Phone number",
        birthdate: "Date of birth",
        overrideShippingCosts: "Override shipping costs",
        noShippingCosts: "Free shipping",
        monday: "Monday",
        tuesday: "Tuesday",
        wednesday: "Wednesday",
        thursday: "Thursday",
        friday: "Friday",
        saturday: "Saturday",
        sunday: "Sunday",
        searchLocation: "Search by location",
        moreStores: "More stores",
        lessStores: "Less stores",
        morePickupLocations: "More pick-up points",
        lessPickupLocations: "Less pick-up points",
        openingTimes: "Opening times",
        weeklyOpeningTimes: "Weekly opening times",
        extraOpeningTimes: "Extra opening times",
        placeOrder: "Place order",
        thanks: "Thank you!",
        orderWasReceived: "Your order has been received.",
        youCanContinueShopping: "You can continue shopping by clicking the button below.",
        continueShopping: "Continue shopping",
        printOrderText: "To print the order, click the button below.",
        printOrder: "Print order",
        noPhoneNumber: "No phone number",
        paymentOptions: "Payment options"
    },
    navigation: {
        collection: "Collection"
    },
    messages: {
        error: {
            orderError: "Something went wrong while placing your order.",
            addToCartError: "Something went wrong. The product could not be added to the cart.",
            saveCustomerError: "Something went wrong. Your data could not be saved.",
            somethingWentWrong: "Something went wrong. Please contact your system administrator."
        },
        warning: {
            discountInvalid: "Discount equals or exceeds the total amount of all products. Please adjust the discount.",
            editDiscount: "Edit discount.",
            maxAmountItems: (amount) => `Max. ${amount} pieces`,
            noStock: (name) => `${name} is out of stock. Please remove this item from your shopping cart.`,
            oneInStock: (name) => `${name} has only one item left in stock. Please change the quantity of this product if you want to continue.`,
            lessInStock: (stockAmount, name, chosenAmount) =>
                `${name} has only ${stockAmount} items in stock. You chose ${chosenAmount}. Please change the quantity of this product if you want to continue.`,
            noStockForSize: (name, size) => `${name} with size ${size} is out of stock. Please remove this product from the shopping cart.`,
            notEnoughStockForSize: (name, size, stock) =>
                `${name} with size ${size} has not enough stock available. Still ${stock} in stock. Please adjust the quantity of this product and try again .`,
            noProductsInCartInStock: "None of the items in your shopping cart are in stock.",
            orderWasNotPlaced: "Order has not been placed",
            postNlLocationsFetchFailed: "Note: PostNL locations could not be retrieved, view the browser console for more details.",
            postNlLocationsError: "There was an error retrieving PostNL locations.",
            sizeColorComboNotFound: "The selected color/size combination could not be found.",
            noAccess: "No access.",
            noAccessInKioskMode: "You cannot access this page in kiosk mode.",
            notFound: "Not found.",
            noProductFoundWithBarcode: (barcode) => `No product was found with barcode ${barcode}.`,
            noBranchMatch: "Note: Your account is not linked to a store, therefore you cannot place orders.",
            branchFetchFailedNoMatch:
                "Note: Something went wrong while retrieving the locations. As a result, no link to a branch was found and you cannot place orders."
        },
        info: {
            noProductsInCart: "You have no products in your shopping cart.",
            shippingCostsInfo: "Shipping costs are recalculated when you choose a shipping method on step 3 of the checkout.",
            amountUpdated: (name, changedAmount) => `The amount for ${name} has been changed to ${changedAmount}.`,
            productWithColorAndSizeRemoved: (name, color, size) =>
                `The product ${name} with color ${color} in size ${size} has been removed from your shopping cart.`,
            productWithSizeRemoved: (name, size) => `The product ${name} in size ${size} has been removed from your shopping cart.`,
            noResults: "No results found.",
            noResultsSearchTerm: (term) => `No results found for the search term <strong><em>${term}</em></strong>`,
            amountCustomersFound: (amount) => `${amount} customer(s) found.`,
            customerResultsExceeded: "<b>Note:</b> Up to 10 results are shown. If the desired customer is not listed, you need to refine the search.",
            fieldMarkedWith: "Fields marked with a",
            areRequired: "are required fields",
            branchDistanceCalculationFailed: "Note: The distances of the branches could not be calculated, see the browser console for more details.",
            dataWillBeRefreshed: "The data of the application is outdated and is being refreshed automatically, please wait.",
            couldNotFetchAddress: "The address cannot be filled in automatically. Please enter it manually.",
            noPaymentOptions: "No payment options were found, your order will be sent to the cash register.",
            noProductsFound: "No products found",
            noProductsFoundInCategoryWithSearchTermAndFilters: (categoryName, searchTerm) =>
                `No products were found in the category "<b>${categoryName}</b>" with the search term "<b>${searchTerm}</b>" and the selected filter(s).`,
            noProductsFoundInCategoryWithSearchTerm: (categoryName, searchTerm) =>
                `No products were found in the category "<b>${categoryName}</b>" with the search term "<b>${searchTerm}</b>".`,
            noProductsFoundWithSearchTermAndFilters: (searchTerm) =>
                `No products were found with the search term "<b>${searchTerm}</b>" and the selected filter(s).`,
            noProductsFoundWithSearchTerm: (searchTerm) =>
                `No products were found with the search term "<b>${searchTerm}</b>". Please use a different search term.`,
            noProductsFoundChooseOption: "Choose one of the option(s) below to continue:"
        },
        success: {
            orderWasPlaced: "Your order has been placed.",
            customerWasSelected: (name) => `<b>${name}</b> has been successfully selected as a customer.`,
            customerWasDeselected: (name) => `<b>${name}</b> was successfully deselected.`,
            customerWasCreated: (name) => `<b>${name}</b> has been successfully created.`,
            customerWasUpdated: (name) => `<b>${name}</b> has been successfully updated.`,
            hasBeenAddedToThe: "has been added to the",
            detailsWereSaved: "Data has been saved successfully."
        },
        validation: {
            pincodeIncorrect: "The store security code is incorrect.",
            searchTermRequired: `The field "Search term" is a required field, please fill it in.`,
            postalCodeInvalid: "Invalid zip code & house number, please enter a valid zip code and house number.",
            choosePickupLocation: "You must select a pick up location.",
            choosePickupStore: "You must select a pick up store.",
            addressNotFound: "No street and/or city has been found with the current zip code and house number.",
            noBranchOrderDisallowed: "You cannot place an order because you are not linked to a branch.",
            selectAColorPlease: (colorTypeName) => `Please select a ${colorTypeName}.`,
            selectASizePlease: (sizeTypeName) => `Please select a ${sizeTypeName}.`,
            selectASubSizePlease: (subSizeTypeName) => `Please select a ${subSizeTypeName}.`,
            selectAnAmountPlease: "Please select an amount.",
            notEnoughInStock: "There is not enough in stock, adjust the quantities",
            firstNameCharAmount: "First name can be up to 30 characters.",
            lastNameCharAmount: "The last name can contain a maximum of 30 characters.",
            infixCharAmount: "The prefix can contain a maximum of 30 characters.",
            invalidDate: "The date is invalid.",
            phoneNumberCharAmount: "The phone number can contain up to 50 characters.",
            emailAlreadyExists: "The entered email address is already in use, please enter a different email address.",
            customerNumberAlreadyExists: "The customer number you entered is already in use, please enter a different customer number.",
            fieldRequired: "This field is required",
            fieldValidEmail: "This field must contain a valid email address.",
            chooseSpecifcity: "Specifying a delivery address is mandatory.",
            productUnavailable: (name) => `${name} est actuellement indisponible, veuillez réessayer plus tard.`,
            productHasNoStock: (name) => `${name} is currently out of stock, please try again later.`,
            houseNoCharAmount: "The house number may contain a maximum of 10 characters.",
            houseNoExtCharAmount: "The house number addition may contain a maximum of 10 characters."
        },
        loading: {
            default: "Loading...",
            branches: "Loading branches...",
            products: "Loading products...",
            properties: "Loading properties...",
            propertyTypes: "Loading properties types...",
            categories: "Loading categories...",
            inventory: "Loading stock...",
            prices: "Loading prices...",
            productProperties: "Loading product properties...",
            inventoryLinking: "Linking stock...",
            settings: "Loading settings...",
            catalog: "Setting up catalog...",
            detailsAreBeingSaved: "Details are being saved...",
            oneMomentPlease: "One moment please...",
            orderIsBeingPlaced: "Your order is being placed...",
            searchingProduct: "Searching for product..."
        }
    }
};

export default enGb;
