const nlNl = {
    global: {
        appName: "Digital Sales Assistant",
        articleGroups: "Artikelgroepen",
        articleNumber: "Artikelnummer",
        fabricComposition: "Stofsamenstelling",
        typeToSearch: "Typ om te zoeken...",
        currentCustomer: "Huidige klant",
        deselectCustomer: "Klant deselecteren",
        collection: "Collectie",
        searchTermResults: (searchterm) => `Resultaten zoekopdracht "${searchterm}"`,
        articlesAmount: (amount) => `${amount} artikel(en)`,
        showArticlesAmount: (amount) => `${amount} artikel(en) bekijken`,
        filters: "Filters",
        categories: "Categorieën",
        color: "Kleur",
        colors: "Kleuren",
        size: "Maat",
        sizes: "Maten",
        brand: "Merk",
        brands: "Merken",
        gender: "Geslacht",
        search: "Zoeken",
        price: "Prijs",
        name: "Naam",
        name2: "Naam 2",
        noFiltersFound: (filterName) => `Geen ${filterName} gevonden.`,
        moreFilters: (filterName) => `Meer ${filterName}`,
        lessFilters: (filterName) => `Minder ${filterName}`,
        inStock: "Op voorraad",
        selectedFilters: "Geselecteerde filter(s)",
        deleteAllFilters: "Verwijder alles",
        sortOptionName: "Naam",
        sortOptionPriceAsc: "Prijs oplopend",
        sortOptionPriceDesc: "Prijs aflopend",
        amount: "Aantal",
        day: "Dag",
        time: "Tijd",
        reason: "Reden",
        noProductFound: "Product niet gevonden",
        backToOverview: "Terug naar collectie",
        back: "Terug",
        goBack: "ga terug",
        gallery: "Gallerij",
        productInformation: "Productinformatie",
        stockInformation: "Voorraadinformatie",
        shelfInformation: "Schapinformatie",
        expectedInventoryInformation: "Verwachte voorraad",
        noStockInfoFound: "Er is geen voorraad informatie gevonden.",
        noSizesFound: "Er zijn geen maten gevonden.",
        noStoresFound: "Er zijn geen winkels gevonden",
        noPickupPointsFoundWithZipcode: (name) => `Er zijn geen ${name} afhaalpunten gevonden met de opgegeven postcode.`,
        noStockFound: "Er is geen voorraad gevonden.",
        totalStock: "Totale voorraad",
        maxToOrder: "Max. te bestellen",
        moreBranches: "Meer vestigingen",
        lessBranches: "Minder vestigingen",
        addToCart: "Voeg toe aan winkelwagen",
        kioskMode: "Kiosk modus",
        on: "Aan",
        viewStockInformation: "Voorraadinformatie bekijken",
        viewShelfInformation: "Schapinformatie bekijken",
        none: "Geen",
        isSellableWithoutStockClarification: "Let op: Dit artikel is momenteel niet op voorraad. Bij het bestellen wordt er een inkooporder aangemaakt.",
        isSellableWithoutStockWithStockClarification: (amountType, amount) =>
            `Let op: Voor dit artikel ${
                amountType === "plural" ? "zijn er nog" : "is er nog"
            } ${amount} op voorraad. Als u er meer besteld wordt er een inkooporder aangemaakt.`,
        externalStock: "Externe voorraad",
        setHasFollowingProducts: "Deze set bestaat uit de volgende producten",
        confirm: "Bevestigen",
        vendorArticleNumber: "Artikelnr. leverancier",
        xItemsExpectedOnDate: (amount, date) => (amount === 1 ? `${amount} stuk op ${date}` : `${amount} stuks op ${date}`),
        noExpectedInventoryFound: "Geen verwachte voorraad gevonden.",
        information: "Informatie",
        inventory: "Voorraad",
        informationAboutArticle: "Informatie over artikel",
        moreInformation: "Meer informatie",
        upwardsOf: "Vanaf",
        filterProperties: "Filter kenmerken...",
        barcode: "Barcode",
        ean: "EAN",
        selectOne: "Maak een keuze...",
        outOfStock: "Niet voorradig",
        searchInAllCategories: "Zoek in alle categorieën",
        searchWithoutFilters: "Zoek zonder filters"
    },
    checkout: {
        shoppingCart: "Winkelwagen",
        customerSelection: "Klant selectie",
        customerDetails: "Klant gegevens",
        orderOverview: "Besteloverzicht",
        billingAddress: "Factuuradres",
        otherAddress: "Ander adres",
        deliveryMethod: "Verzendmethode",
        homeDelivery: "Thuisbezorgen",
        storeDelivery: "Afhalen in de winkel",
        postNlDelivery: "Afhalen bij een PostNL punt",
        dhlDelivery: "Afhalen bij een DHL punt",
        pickupPointDelivery: "Afhalen bij een afhaalpunt",
        free: "Gratis",
        mr: "Dhr.",
        mrs: "Mevr.",
        continue: "Verder",
        continueOrdering: "Doorgaan met bestellen",
        editTotalDiscount: "Bonkorting wijzigen",
        applyTotalDiscount: "Bonkorting toepassen",
        removeTotalDiscount: "Bonkorting verwijderen",
        editDiscount: "Korting wijzigen",
        applyDiscount: "Korting toepassen",
        removeDiscount: "Korting verwijderen",
        subTotal: "Subtotaal",
        discount: "Korting",
        chooseDiscountReason: "Kies kortingsreden",
        shippingCosts: "Verzendkosten",
        total: "Totaal",
        currencyAmount: "Bedrag",
        percentage: "Percentage",
        delete: "Verwijderen",
        cancel: "Annuleren",
        save: "Opslaan",
        previous: "Vorige",
        next: "Volgende",
        toDetails: "Naar gegevens",
        storeSafetyCode: "Winkel veiligheidscode",
        login: "Inloggen",
        customer: "Klant",
        customerNumber: "Klantnummer",
        customerNumberAbbr: "Klantnr.",
        firstName: "Voornaam",
        infix: "Tussenvoegsel",
        lastName: "Achternaam",
        postalCode: "Postcode",
        houseNumber: "Huisnummer",
        houseNumberAbbr: "Huisnr.",
        address: "Adres",
        emailAddress: "E-mailadres",
        emailAddressAbbr: "E-mail",
        action: "Actie",
        select: "Selecteren",
        chooseOtherCustomer: "Andere klant kiezen",
        results: "Resultaten",
        searchBy: "Zoeken op",
        postalCodeHouseNumber: "Postcode + Huisnummer",
        condition: "Conditie",
        beginsWith: "Begint met",
        endsWith: "Eindigt met",
        equals: "Gelijk aan",
        contains: "Bevat",
        searchTerm: "Zoekterm",
        searchCustomer: "Klant zoeken",
        createCustomer: "Klant aanmaken",
        continueAsGuest: "Doorgaan zonder klantenpas",
        or: "Of",
        edit: "Bewerken",
        deliveryAddress: "Bezorgadres",
        theNetherlands: "Nederland",
        belgium: "België",
        germany: "Duitsland",
        country: "Land",
        houseNumberExtension: "Toevoeging",
        street: "Straat",
        city: "Plaats",
        salutation: "Aanhef",
        phonenumber: "Telefoonnummer",
        birthdate: "Geboortedatum",
        overrideShippingCosts: "Verzendkosten overschrijven",
        noShippingCosts: "Geen verzendkosten",
        monday: "Maandag",
        tuesday: "Dinsdag",
        wednesday: "Woensdag",
        thursday: "Donderdag",
        friday: "Vrijdag",
        saturday: "Zaterdag",
        sunday: "Zondag",
        searchLocation: "Zoeken op locatie",
        moreStores: "Meer winkels",
        lessStores: "Minder winkels",
        morePickupLocations: "Meer afhaalpunten",
        lessPickupLocations: "Minder afhaalpunten",
        openingTimes: "Openingstijden",
        weeklyOpeningTimes: "Wekelijkse openingstijden",
        extraOpeningTimes: "Extra openingstijden",
        placeOrder: "Bestelling plaatsen",
        thanks: "Bedankt!",
        orderWasReceived: "Uw bestelling is in goede orde ontvangen.",
        youCanContinueShopping: "U kunt meteen verder winkelen door op de onderstaande knop te klikken.",
        continueShopping: "Verder winkelen",
        printOrderText: "Klik op de onderstaande knop om een overzicht van uw bestelling te printen.",
        printOrder: "Bestelling printen",
        noPhoneNumber: "Geen telefoonnummer",
        paymentOptions: "Betaalopties",
        requestedDeliveryDate: "Gewenste bezorgdatum",
        requestedPickupDate: "Gewenste afhaaldatum",
        pickupDate: "Afhaaldatum",
        deliveryDate: "Bezorgdatum"
    },
    navigation: {
        collection: "Collectie"
    },
    messages: {
        error: {
            orderError: "Er is iets fout gegaan bij het plaatsen van uw bestelling.",
            addToCartError: "Er is iets fout gegaan. Het product kon niet worden toegevoegd aan de winkelwagen.",
            saveCustomerError: "Er is iets fout gegaan, uw gegevens konden niet opgeslagen worden.",
            somethingWentWrong: "Er is iets fout gegaan, neem contact op met de systeembeheerder."
        },
        warning: {
            discountInvalid: "Korting is gelijk aan of hoger dan het totaalbedrag van alle producten. Pas de korting aan.",
            editDiscount: "Pas de korting aan.",
            maxAmountItems: (amount) => `Max. ${amount} stuks`,
            noStock: (name) => `${name} is op dit moment uitverkocht. Verwijder dit item uit de winkelwagen als u verder wilt gaan.`,
            oneInStock: (name) => `${name} heeft nog 1 stuk op voorraad. Wijzig het aantal van dit item als u verder wilt gaan.`,
            lessInStock: (stockAmount, name, chosenAmount) =>
                `${name} heeft nog ${stockAmount} stuks op voorraad. U koos er ${chosenAmount}. Wijzig het aantal als u verder wilt gaan.`,
            noStockForSize: (name, size) => `${name} in maat ${size} is uitverkocht. Verwijder dit item uit de winkelwagen als u verder wilt gaan.`,
            notEnoughStockForSize: (name, size, stock) =>
                `${name} in maat ${size} heeft niet meer genoeg voorraad. Nog ${stock} op voorraad. Pas het aantal aan van dit item en probeer het opnieuw.`,
            noProductsInCartInStock: "Geen van de items in uw winkelwagen zijn op voorraad.",
            orderWasNotPlaced: "De bestelling is niet geplaatst.",
            postNlLocationsFetchFailed: "Let op: Er konden geen PostNL locaties opgehaald worden. Bekijk eventueel de browser console voor meer details.",
            postNlLocationsError: "Er is een fout opgetreden bij het ophalen van PostNL locaties.",
            sizeColorComboNotFound: "De geselecteerde kleur / maat combinatie kon niet worden gevonden.",
            noAccess: "Geen toegang.",
            noAccessInKioskMode: "U heeft geen toegang tot deze pagina in kiosk modus.",
            notFound: "Niet gevonden.",
            noProductFoundWithBarcode: (barcode) => `Er is geen product gevonden met barcode ${barcode}.`,
            noBranchMatch: "Let op: Uw account is niet gekoppeld aan een vestiging. Hierdoor kunt u geen bestellingen plaatsen.",
            branchFetchFailedNoMatch:
                "Let op: Er is iets fout gegaan bij het ophalen van de vestigingen. Hierdoor is er geen koppeling aan een vestiging gevonden en kunt u geen bestellingen plaatsen."
        },
        info: {
            noProductsInCart: "Er bevinden zich geen producten in uw winkelwagen.",
            shippingCostsInfo: "Verzendkosten worden herberekend bij het kiezen van de verzendmethode in stap 3 van de checkout.",
            amountUpdated: (name, changedAmount) => `Het aantal voor ${name} is aangepast naar ${changedAmount}.`,
            productWithColorAndSizeRemoved: (name, color, size) => `${name} met de kleur ${color} in maat ${size} is verwijderd uit uw winkelwagen.`,
            productWithSizeRemoved: (name, size) => `${name} in maat ${size} is verwijderd uit uw winkelwagen.`,
            noResults: "Geen resultaten gevonden.",
            noResultsSearchTerm: (term) => `Geen resultaten gevonden met de zoekterm <strong><em>${term}</em></strong>`,
            amountCustomersFound: (amount) => `${amount} klant(en) gevonden.`,
            customerResultsExceeded:
                "<b>Let op:</b> Er worden maximaal 10 resultaten getoond. Als de gewenste klant er niet tussen staat dient u de zoekopdracht te verfijnen.",
            fieldMarkedWith: "Velden gemarkeerd met een",
            areRequired: "zijn verplichte velden",
            branchDistanceCalculationFailed:
                "Let op: De afstanden van de vestigingen konden niet berekend worden, bekijk de browser console voor meer details.",
            dataWillBeRefreshed: "De data van de applicatie is verouderd en wordt automatisch ververst. Een moment geduld alstublieft.",
            couldNotFetchAddress: "Het adres kan niet automatisch worden ingevuld. Vul deze handmatig in alstublieft.",
            noPaymentOptions: "Er zijn geen betaalopties gevonden, uw bestelling wordt naar de kassa gestuurd.",
            noProductsFound: "Geen producten gevonden",
            noProductsFoundInCategoryWithSearchTermAndFilters: (categoryName, searchTerm) =>
                `Er zijn geen producten gevonden in de categorie "<b>${categoryName}</b>" met de zoekterm "<b>${searchTerm}</b>" en de geselecteerde filter(s).`,
            noProductsFoundInCategoryWithSearchTerm: (categoryName, searchTerm) =>
                `Er zijn geen producten gevonden in de categorie "<b>${categoryName}</b>" met de zoekterm "<b>${searchTerm}</b>".`,
            noProductsFoundWithSearchTermAndFilters: (searchTerm) =>
                `Er zijn geen producten gevonden met de zoekterm "<b>${searchTerm}</b>" en de geselecteerde filter(s).`,
            noProductsFoundWithSearchTerm: (searchTerm) => `Er zijn geen producten gevonden met de zoekterm "<b>${searchTerm}</b>". Gebruik een andere
            zoekterm.`,
            noProductsFoundChooseOption: "Kies één van de onderstaande optie(s) om verder te gaan:"
        },
        success: {
            orderWasPlaced: "Uw bestelling is geplaatst.",
            customerWasSelected: (name) => `${name} is succesvol geselecteerd als klant.`,
            customerWasDeselected: (name) => `${name} is gedeselecteerd.`,
            customerWasCreated: (name) => `${name} is succesvol aangemaakt.`,
            customerWasUpdated: (name) => `${name} is succesvol gewijzigd.`,
            hasBeenAddedToThe: "is toegevoegd aan de",
            detailsWereSaved: "Gegevens zijn succesvol opgeslagen."
        },
        validation: {
            pincodeIncorrect: "De winkel veiligheidscode is onjuist.",
            searchTermRequired: `Het veld "Zoekterm" is een verplicht veld. Vul deze alsnog in.`,
            postalCodeInvalid: "Ongeldig postcode en huisnummer. Vul een geldig postcode én huisnummer in.",
            choosePickupLocation: "U dient een afhaallocatie te selecteren.",
            choosePickupStore: "U dient een afhaalwinkel te selecteren.",
            addressNotFound: "Er is geen straat- en/of plaatsnaam gevonden met de huidige postcode en huisnummer.",
            noBranchOrderDisallowed: "U kunt geen bestelling plaatsen, want u bent niet gekoppeld aan een vestiging.",
            selectAColorPlease: (colorTypeName) => `Selecteer een '${colorTypeName}' alstublieft.`,
            selectASizePlease: (sizeTypeName) => `Selecteer een '${sizeTypeName}' alstublieft.`,
            selectASubSizePlease: (subSizeTypeName) => `selecteer een '${subSizeTypeName}' alstublieft.`,
            selectAnAmountPlease: "Geef een aantal op alstublieft.",
            notEnoughInStock: "Er is niet genoeg op voorraad. Pas de aantallen aan.",
            firstNameCharAmount: "De voornaam mag maximaal 30 karakters bevatten.",
            lastNameCharAmount: "De achternaam mag maximaal 30 karakters bevatten.",
            infixCharAmount: "De tussenvoegsel mag maximaal 30 karakters bevatten.",
            invalidDate: "De datum is ongeldig.",
            phoneNumberCharAmount: "Het telefoonnummer mag maximaal 50 karakters bevatten.",
            emailAlreadyExists: "Het ingevulde e-mailadres is al in gebruik. Vul aub een ander e-mailadres in.",
            customerNumberAlreadyExists: "Het ingevulde klantnummer is al in gebruik. Vul aub een ander klantnummer in.",
            fieldRequired: "Dit veld is verplicht.",
            fieldValidEmail: "Dit veld moet een geldig e-mailadres bevatten.",
            chooseSpecifcity: "Het opgeven van een bezorgadres is verplicht.",
            productUnavailable: (name) => `${name} is currently unavailable, please try again later.`,
            productHasNoStock: (name) => `${name} is momenteel niet op voorraad, probeer het later nog een keer.`,
            houseNoCharAmount: "Het huisnummer mag maximaal 10 karakters bevatten.",
            houseNoExtCharAmount: "De huisnummertoevoeging mag maximaal 10 karakters bevatten."
        },
        loading: {
            default: "Bezig met laden...",
            branches: "Vestigingen worden geladen...",
            products: "Producten worden geladen...",
            properties: "Eigenschappen worden geladen...",
            propertyTypes: "Eigenschappen types worden geladen...",
            categories: "Categorieën worden geladen...",
            inventory: "Voorraden worden geladen...",
            prices: "Prijzen worden geladen...",
            productProperties: "Producteigenschappen worden geladen...",
            inventoryLinking: "Voorraden worden gekoppeld...",
            settings: "Instellingen worden geladen...",
            catalog: "Catalogus wordt samengesteld...",
            detailsAreBeingSaved: "Gegevens worden opgeslagen...",
            oneMomentPlease: "Een moment geduld aub...",
            orderIsBeingPlaced: "Uw bestelling wordt geplaatst...",
            searchingProduct: "Product opzoeken..."
        }
    }
};

export default nlNl;
